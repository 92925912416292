<template>
  <v-dialog v-model="show" activator="parent" width="70%">
    <v-card v-if="limit" class="modal" :dark="$dark.get()">
      <v-card-title class="flex justify-center">
        {{ editMode ? "Изменение лимита" : "Создание лимита" }}
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="limit.departmentId"
          class="modal__select"
          label="Филиал"
          :disabled="editMode"
          :items="departments"
          item-text="departmentName"
          item-value="departmentId"
        ></v-select>
        <v-text-field
          v-model.number="limit.minOrderSum"
          :rules="[$validate.required]"
          label="Сумма лимита"
          type="number"
          required
          clearable
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="flex justify-center">
        <v-btn @click="show = false">Отмена </v-btn>
        <v-btn v-if="editMode" color="primary" @click="$emit('editLimit')"
          >изменить
        </v-btn>
        <v-btn v-else color="primary" @click="$emit('createLimit')"
          >создать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LimitModal",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Object,
      default() {
        return {};
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    showModal() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__actions {
    width: 100%;
    justify-content: right;
  }
}
</style>
